import { Component, OnInit } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {

  CampId:any;
  placeArr = []

  constructor(private route: ActivatedRoute, private http:HttpClient) {


  }

  ngOnInit(): void {

    this.route.paramMap.subscribe(( params ) => {
      this.CampId = params.get('url') ;
    })


    const Apiurl = "https://kampalanlari.net/Api/Places?url=" + this.CampId;
    this.http.get(Apiurl).toPromise().then(res =>{
      const json = JSON.parse(JSON.stringify(res));
      console.log(json.places);
      this.placeArr = json.places;
      console.log(this.placeArr);

    })

  }

}
