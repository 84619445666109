<mat-tab-group>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">home</mat-icon>
      Anasayfa
    </ng-template>

    <div class="row">
      <div *ngFor="let item of placeArr" class="col-sm-3">
          <div class="card">
              <img src = "https://kampalanlari.net//Gorsel/Thumbnail/{{item['photo']}}" class="card-img-top" alt="..." [ngStyle]="{'width': 200}">
              <div class="card-body">
                <h5 class="card-title">{{item['campname']}}</h5>
                <p class="card-text">{{item['cityName']}} {{item['provinceName']}}</p>
                <button (click)="fncGotoDetail(item['url'])" class="btn btn-primary">Detay</button>
              </div>
            </div>
      </div>
    </div>

  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">map</mat-icon>
      Bölgeler
    </ng-template>


    <mat-grid-list cols="2" rowHeight="10rem">
      <mat-grid-tile>
        <img class="tile-image" src="https://kampalanlari.net/Gorsel/Thumbnail/karagol-tabiat-parki.jpg">
      </mat-grid-tile>
      <mat-grid-tile>
        <img class="tile-image" src="https://kampalanlari.net/Gorsel/akyaka-orman-kampi-33444.jpg">
      </mat-grid-tile>
      <mat-grid-tile>
        <img class="tile-image" src="https://kampalanlari.net/Gorsel/Thumbnail/baskonus-yaylasi.jpg">
      </mat-grid-tile>
      <mat-grid-tile>
        <img class="tile-image" src="https://kampalanlari.net/Gorsel/Thumbnail/karagol-tabiat-parki.jpg">
      </mat-grid-tile>
      <mat-grid-tile>
        <img class="tile-image" src="https://kampalanlari.net/Gorsel/Thumbnail/karagol-tabiat-parki.jpg">
      </mat-grid-tile>
      <mat-grid-tile>
        <img class="tile-image" src="https://kampalanlari.net/Gorsel/Thumbnail/karagol-tabiat-parki.jpg">
      </mat-grid-tile>
    </mat-grid-list>

  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">campaign</mat-icon>
      Şehirler
    </ng-template>


    <div fxLayout="row wrap">
      <mat-card *ngFor="let shoe of typesOfShoes" fxFlex="50">{{shoe}}</mat-card>
  </div>


  </mat-tab>
</mat-tab-group>


