import { Component, OnInit } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {


  typesOfShoes: string[] = ['Boots', 'Clogs', 'Loafers', 'Moccasins', 'Sneakers'];

  placeArr = []

  constructor(private router: Router, private http:HttpClient) {

    const params = {
      ref: "5380f5dbcc3b1021f93ab24c3a1aac24",
      start: "0"
    }
    const url = "https://kampalanlari.net/Api/Places"

    this.http.get(url, {params}).toPromise().then(res =>{
      const json = JSON.parse(JSON.stringify(res));
      console.log(json.places);
      this.placeArr = json.places;
      console.log(this.placeArr);

    })

  }

  fncGotoDetail( url:string ) {
    this.router.navigate( ["detail", url]);
  }

  ngOnInit(): void {
  }

}
